import React from 'react';
import './App.css';

//components
import TodoList from "./components/TodoList";

function App() {

  return (
    <div className="App">
      <TodoList />
    </div>
  );
}

export default App;
